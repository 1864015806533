import { ReactSetter } from '../types';
import React from 'react';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import ShortcutIcon from '../ShortcutIcon';

export interface IAppearanceOptions {
    saturation: number;
    directionalLightIntensity: number;
    ambientLightIntensity: number;
    doubleSided: boolean;
    showEdges: boolean;
    meshCenterRotation: boolean;
    showCameraTargetMarker: boolean;
    alternateControls: boolean;
    showMarkerSphere: boolean;
}

interface AppearanceOptionsProps {
    appearanceOptions: IAppearanceOptions;
    setAppearanceOptions: ReactSetter<IAppearanceOptions>;
    isFilterFocused: boolean;
}

export default function AppearanceOptions({
    appearanceOptions,
    setAppearanceOptions,
    isFilterFocused,
}: AppearanceOptionsProps) {
    const {
        saturation,
        ambientLightIntensity,
        directionalLightIntensity,
        doubleSided,
        showEdges,
        meshCenterRotation,
        showCameraTargetMarker,
        alternateControls,
        showMarkerSphere,
    } = appearanceOptions;

    function apply(changes: Partial<IAppearanceOptions>) {
        setAppearanceOptions({
            ...appearanceOptions,
            ...changes,
        });
    }

    const toggleMeshCenterRotation = (_code?: string, e?: KeyboardEvent) => {
        if (isFilterFocused && e?.ctrlKey === false) {
            return;
        }
        e?.preventDefault();
        apply({ meshCenterRotation: !meshCenterRotation });
    };
    useKeyboardShortcuts(['KeyC'], toggleMeshCenterRotation);

    const toggleMarkerSphereVisible = (_code?: string, e?: KeyboardEvent) => {
        if (isFilterFocused && e?.ctrlKey === false) {
            return;
        }
        e?.preventDefault();
        apply({ showMarkerSphere: !showMarkerSphere });
    };
    useKeyboardShortcuts(['KeyM'], toggleMarkerSphereVisible);

    const shortcutPrefix = isFilterFocused ? '^' : '';

    return (
        <>
            <label>Mesh colors saturation</label>
            <input
                type={'range'}
                min={-1.0}
                max={1.0}
                step={0.01}
                value={saturation}
                onChange={e => apply({ saturation: parseFloat(e.target.value) })}
            />
            <label>Directional light intensity</label>
            <input
                type={'range'}
                min={0.0}
                max={5.0}
                step={0.0125}
                value={directionalLightIntensity}
                onChange={e => apply({ directionalLightIntensity: parseFloat(e.target.value) })}
            />
            <label>Ambient light intensity</label>
            <input
                type={'range'}
                min={0.0}
                max={3.0}
                step={0.0125}
                value={ambientLightIntensity}
                onChange={e => apply({ ambientLightIntensity: parseFloat(e.target.value) })}
            />

            <label>Mesh surface</label>
            <button onClick={e => apply({ doubleSided: !doubleSided })}>
                {doubleSided ? 'Make Single-Sided' : 'Make Double-Sided'}
            </button>
            <button onClick={e => apply({ showEdges: !showEdges })}>{showEdges ? 'Hide Edges' : 'Show Edges'}</button>

            <label>Mesh center rotation</label>
            <span>
                <button onClick={e => apply({ meshCenterRotation: !meshCenterRotation })}>
                    <ShortcutIcon character={`${shortcutPrefix}C`} />
                    {meshCenterRotation ? 'Rotate around canvas center' : 'Rotate around mesh center'}
                </button>
                <button onClick={e => apply({ showCameraTargetMarker: !showCameraTargetMarker })}>
                    {showCameraTargetMarker ? 'Hide center marker' : 'Show center marker'}
                </button>
            </span>

            <label>Alternate controls</label>
            <span>
                <button onClick={e => apply({ alternateControls: !alternateControls })}>
                    {alternateControls ? 'Regular controls' : 'Alternate controls'}
                </button>
                <button onClick={e => apply({ showMarkerSphere: !showMarkerSphere })}>
                    <ShortcutIcon character={`${shortcutPrefix}M`} />
                    {showMarkerSphere ? 'Hide marker sphere' : 'Show marker sphere'}
                </button>
            </span>
        </>
    );
}
