import React from 'react';
import ShortcutIcon from '../ShortcutIcon';
import useLocalStorage from '../useLocalStorage';

import styles from './styles.module.css';

interface HelpKeyProps {
    showModifier: boolean;
    alternateControls: boolean;
}

function HelpKey({ showModifier, alternateControls }: HelpKeyProps) {
    const [visible, setVisible] = useLocalStorage('showHelpKey', true);
    const shortcutPrefix = showModifier ? '^' : '';

    return (
        <div className={styles.container}>
            <p>
                Keyboard shortcuts <button onClick={() => setVisible(!visible)}>{visible ? 'Hide' : 'Show'}</button>
            </p>
            <div className={`${visible ? '' : styles.invisible}`}>
                <br />
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}Space`} />: Show/hide side bar
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}0`} />: Toggle all labels visibility
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}1`} />: Move tool
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}2`} />-
                    <ShortcutIcon character={`${shortcutPrefix}9`} />
                    <ShortcutIcon character={`${shortcutPrefix}Q`} />-
                    <ShortcutIcon character={`${shortcutPrefix}I`} />: Change active label
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Scroll" />/
                    <ShortcutIcon character="Shift-Scroll" />/
                    <ShortcutIcon character={`${shortcutPrefix}-`} />
                    <ShortcutIcon character={`${shortcutPrefix}+`} />/
                    <ShortcutIcon character={`${shortcutPrefix}=`} />: Change marker size
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}F`} />, <ShortcutIcon character={`Esc`} />: Focus,
                    unfocus search bar
                </p>
                <p>
                    <ShortcutIcon character={`${alternateControls ? 'Right-Click' : 'Shift'}-Drag`} />: Camera rotation
                </p>
                <p>
                    <ShortcutIcon character={`${alternateControls ? 'Middle' : 'Right'}-Click Drag`} />/
                    <ShortcutIcon character="Shift-Alt-Drag" />: Camera panning
                </p>
                <p>
                    <ShortcutIcon character={`${shortcutPrefix}G`} />: Toggle to paint mode
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Shift-Alt-Click" />/
                    <ShortcutIcon character="Ctrl-Shift-Option-Click" />/
                    <ShortcutIcon character={`${shortcutPrefix}H`} />: Fill label class
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Click" /> / <ShortcutIcon character={`${shortcutPrefix}J`} />: Pick
                    label class
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Alt-Click" />/<ShortcutIcon character="Ctrl-Option-Click" />/
                    <ShortcutIcon character={`${shortcutPrefix}K`} />: Erase class
                </p>
                <p>
                    <ShortcutIcon character="Alt-Click" />/<ShortcutIcon character="Option-Click" />/
                    <ShortcutIcon character={`${shortcutPrefix}L`} />: Erase group
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Z" /> / <ShortcutIcon character="Cmd-Z" />: Undo last paint
                </p>
                <p>
                    <ShortcutIcon character="Ctrl-Y" /> / <ShortcutIcon character="Cmd-Y" /> /{' '}
                    <ShortcutIcon character="Ctrl-Shift-Z" /> / <ShortcutIcon character="Cmd-Shift-Z" />: Redo last
                    paint
                </p>
            </div>
        </div>
    );
}

export default HelpKey;
